import { Flex } from 'rebass/styled-components';

import { BrowserNotSupported } from '../components/BrowserNotSupported';
import Footer from '../components/Footer';
import Login from '../components/Login';
import Main from '../components/Main';
import SpinLoader from '../components/SpinLoader';
import { useGlobal } from '../hooks/useGlobal';

const Dashboard = () => {
  const { ready, authenticated, isSupported, isMobile } =
    useGlobal();

  if (!ready) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flex={1}
        sx={{ height: '100%' }}
      >
        <SpinLoader primaryColor="#333" secondaryColor="#666" />
      </Flex>
    );
  }

  if (!isSupported || isMobile) {
    return <BrowserNotSupported isMobile={isMobile} />;
  }

  return (
    <>
      <Flex
        flex={1}
        flexDirection="column"
        bg="#181819"
        m={0}
        p={0}
        sx={{ height: '100%', width: '100%' }}
      >
        {!authenticated && <Login />}
        {authenticated && <Main />}
      </Flex>
      <Footer />
    </>
  );
};

export default Dashboard;
