import { FC } from 'react';
import { Box, Flex, Link, Text } from 'rebass/styled-components';

import Logo from '../components/Logo';

interface BrowserNotSupportedProps {
  isMobile: boolean;
}

export const BrowserNotSupported: FC<BrowserNotSupportedProps> = ({
  isMobile,
}) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    flex={1}
    sx={{ height: '100%' }}
  >
    <Flex mx={3} flexDirection="column" sx={{ maxWidth: 400 }}>
      <Box mb={4}>
        <Link href="https://kinema.com" sx={{ fill: '#999', display: 'block' }}>
          <Logo height={['30px', '30px', '30px']} />
        </Link>
      </Box>
      <Text fontSize={4} fontWeight={600} mb={3}>
        Browser not supported
      </Text>
      <Text fontSize={3} fontWeight={400} lineHeight={1.6}>
        <Link href="https://www.google.com/chrome">Google Chrome</Link> version
        73+ {isMobile ? 'on a non-mobile device ' : ''}is required for the
        Offline Cinema Player.
      </Text>
    </Flex>
  </Flex>
);
