import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { CurrentError, useShaka } from '../hooks/useShaka';
import { ShakaErrorCode } from '../lib/shaka/ShakaError';

const getShakaErrorNode = (currentError: CurrentError): ReactNode | null => {
  if (currentError === null || typeof currentError === 'string') {
    return currentError;
  }

  switch (currentError) {
    case ShakaErrorCode.STORAGE_LIMIT_REACHED:
      return (
        <>
          <Text>Storage limit reached.</Text>
          <Text>Make sure you have enough space for the download.</Text>
        </>
      );
    default:
      return <Text>Something went wrong.</Text>;
  }
};

export const ErrorOverlay: FC = () => {
  const { currentError, setCurrentError } = useShaka();
  const errorNode = getShakaErrorNode(currentError);

  const handleClose = () => {
    setCurrentError(null);
  };

  return (
    <Modal
      isOpen={errorNode !== null}
      onClose={handleClose}
      isCentered
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Error</ModalHeader>
        <ModalBody>{errorNode}</ModalBody>
        <ModalFooter>
          <Button
            bg="white"
            width="full"
            onClick={handleClose}
            _hover={{
              bg: 'purple.500',
              color: 'white',
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
