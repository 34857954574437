import * as FullStory from '@fullstory/browser';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocalStorage, useNetworkState } from 'react-use';

import { AuthenticateMutation } from '../graphql/types';
import { Browser } from '../lib/dectectBrowser';
import { useIncognito } from './useIncognito';

export const USER_KEY = 'sp_offline_user';
export const TOKEN_KEY = 'sp_offline_token';

interface GlobalContextProps {
  logout: () => Promise<void>;
  login: (values: any) => void;
  token: string | null | undefined;
  ready: boolean;
  user: any;
  authenticated: boolean;
  network: any;
  isSupported: boolean;
  isMobile: boolean;
  isIncognito: boolean | null;
}

const GlobalContext = React.createContext<GlobalContextProps>({
  logout: async () => Promise.resolve() as any,
  login: async () => {},
  network: {},
  token: null,
  ready: false,
  user: null,
  authenticated: false,
  isSupported: false,
  isMobile: false,
  isIncognito: false,
});

export const GlobalProvider = ({ children }: any) => {
  const browserInfo = useRef(new Browser({ userAgent: navigator.userAgent }));
  const network = useNetworkState();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const { isIncognito } = useIncognito();
  const [user, setUser, removeUser] = useLocalStorage<any>(USER_KEY, null);
  const [token, setToken, removeToken] = useLocalStorage<string | null>(
    TOKEN_KEY,
    null
  );

  useEffect(() => {
    setAuthenticated(Boolean(token && user));
    setReady(true);
  }, [token, user]);

  const login = useCallback(
    ({ user, accessToken }: AuthenticateMutation['authenticate']) => {
      setUser(user);
      setToken(accessToken);

      FullStory.identify(user.id, {
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    },
    [setUser, setToken]
  );

  const logout = useCallback(async () => {
    removeUser();
    removeToken();
    setAuthenticated(false);

    localStorage.clear();

    FullStory.anonymize();
  }, [removeUser, removeToken]);

  return (
    <GlobalContext.Provider
      value={{
        user,
        authenticated,
        ready,
        token,
        network,
        isSupported: browserInfo.current.isChrome,
        isMobile: browserInfo.current.isMobile,
        isIncognito,
        login,
        logout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = React.useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('no provider available');
  }

  return context;
};
