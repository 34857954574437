import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface SpinLoaderProps {
  readonly width?: number;
  readonly height?: number;
  readonly thickness?: number;
  readonly secondaryColor?: string;
  readonly primaryColor?: string;
  readonly loading?: boolean;
}

const SpinLoader = styled.div<SpinLoaderProps>`
  border-radius: 50%;
  border: ${props => `${props.thickness}px solid ${props.secondaryColor}`};
  border-top: ${props => `${props.thickness}px solid ${props.primaryColor}`};
  height: ${props => `${props.height}px`};
  width: ${props => `${props.width}px`};
  animation: ${spin} 1s linear infinite;
`;

SpinLoader.defaultProps = {
  width: 50,
  height: 50,
  thickness: 5,
  secondaryColor: '#c6c5c8',
  primaryColor: '#dfdee1',
};

export default SpinLoader;