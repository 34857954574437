/**
 * https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
 */
export enum ShakaErrorCode {
  /**
   * The storage limit defined in downloadSizeCallback has been reached.
   */
  STORAGE_LIMIT_REACHED = 9014,
}

// export type ShakaError = {
//   code: number;
//   category: number;
//   severity: number;
//   data: any;
//   message?: string;
// };

export enum ShakaErrorLevel {
  Recoverable = 1,
  Critical = 2,
}

export class ShakaError extends Error {
  public code: number;
  public category: number;
  public severity: number;
  public data: any;
  public message: string;

  /**
   * @param {string} code
   * @param {string} reason
   * @param {Boolean} fatal
   */
  constructor(
    code: number,
    category: number,
    severity: number,
    data: any,
    message: string
  ) {
    super(message);

    this.code = code;
    this.category = category;
    this.severity = severity;
    this.data = data;
    this.message = message;

    (this as any).__proto__ = ShakaError.prototype;
  }

  toString() {
    return 'ShakaError' + JSON.stringify(this, null, '  ');
  }
}
