import shaka from 'shaka-player/dist/shaka-player.ui';

export class WatermarkDisplayer {
  private isTextVisible_;
  private videoContainer_;
  private video_;
  private watermarkContainer_: HTMLElement | null;
  private watermarkTimer_;

  /**
   * Constructor
   * @param {HTMLMediaElement} video
   * @param {HTMLElement} videoContainer
   */
  constructor(video: HTMLMediaElement, videoContainer: HTMLElement) {
    /** @private {boolean} */
    this.isTextVisible_ = false;

    /** @private {HTMLMediaElement} */
    this.video_ = video;

    /** @private {HTMLElement} */
    this.videoContainer_ = videoContainer;

    /** @type {HTMLElement} */
    this.watermarkContainer_ = document.createElement('div');
    this.watermarkContainer_.classList.add('shaka-watermark-container');

    this.watermarkContainer_.style.display = 'flex';
    this.watermarkContainer_.style.textAlign = 'left';
    this.watermarkContainer_.style.flexDirection = 'column';
    this.watermarkContainer_.style.alignItems = 'center';
    this.watermarkContainer_.style.position = 'absolute';
    this.watermarkContainer_.style.color = '#fff';
    this.watermarkContainer_.style.opacity = '0.5';
    this.watermarkContainer_.style.alignSelf = 'center';
    this.watermarkContainer_.style.justifySelf = 'center';
    this.watermarkContainer_.style.marginLeft = '30px';
    this.watermarkContainer_.style.fontSize = '1.5rem';
    this.watermarkContainer_.style.display = 'none';
    this.videoContainer_.appendChild(this.watermarkContainer_);

    /** @private {shaka.util.Timer} */
    this.watermarkTimer_ = new shaka.util.Timer(() => {
      // this.checkWatermark();
    }).tickEvery(1);
  }

  /**
   * @override
   * @export
   */
  destroy() {
    // Remove the text container element from the UI.
    if (this.watermarkContainer_) {
      this.videoContainer_.removeChild(this.watermarkContainer_);
      this.watermarkContainer_ = null;
    }

    this.isTextVisible_ = false;

    if (this.watermarkTimer_) {
      this.watermarkTimer_.stop();
    }
  }

  /**
   * @public
   * @returns
   */
  public iTextVisible() {
    return this.isTextVisible_;
  }

  /**
   * Sets watermark display
   * @public
   */
  public setTextVisibility(on: boolean) {
    if (!this.watermarkContainer_) {
      return;
    }

    this.isTextVisible_ = on;
    this.watermarkContainer_.style.display = on ? 'block' : 'none';
  }

  /**
   * Sets watermark text
   * @public
   * @param lines
   * @return
   */
  public setText(lines: string[]) {
    if (!this.watermarkContainer_) {
      return;
    }

    this.watermarkContainer_.innerHTML = `${lines.join('<br />')}`;
  }

  /**
   *
   * @private
   */
  checkWatermark() {}
}
