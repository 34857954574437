import moment from 'moment-timezone';
import muxjs from 'mux.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { Box } from 'rebass/styled-components';
import shaka from 'shaka-player/dist/shaka-player.ui';

import { useGlobal } from '../hooks/useGlobal';
import { useShaka } from '../hooks/useShaka';
import { captureException } from '../lib/sentry';
import { ShakaOfflineManager } from '../lib/shaka/ShakaOfflineManager';

const Player: React.FC<any> = React.forwardRef((props, ref) => {
  const { user } = useGlobal();
  const { videos, playing } = useShaka();
  const [ready, setReady] = useState(false);
  const managerRef = useRef<any>();
  const videoRef = React.useRef<any | null>(null);
  const uiRef = useRef<HTMLElement | null>(null);
  const controller = React.useRef<any>({});
  const [watermark, setWatermark] = useState(false);

  const checkWatermark = useCallback(() => {
    if (!managerRef.current || !videos) {
      return;
    }

    managerRef.current.setVisualWatermark([]);
    setWatermark(false);

    const eventVideo = videos.find(
      (item: any) => item.appMetadata.id === playing
    ) as any;

    if (!eventVideo) {
      return;
    }

    const watermarkDateTime = moment(eventVideo.appMetadata.start)
      .utc()
      .subtract(24, 'hours');
    const now = moment().utc();

    if (watermarkDateTime.isSameOrAfter(now)) {
      managerRef.current.setVisualWatermark([
        `WATERMARK WILL BE REMOVED`,
        `on ${watermarkDateTime.local().format('MMMM, D, YYYY [at] h:mm a')}`,
        user.email,
      ]);
      setWatermark(true);
    } else {
      setWatermark(false);
    }
  }, [videos, playing, user.email]);

  const [, cancel, reset] = useTimeoutFn(checkWatermark, 1000);

  useEffect(() => {
    if (watermark) {
      reset();
    } else {
      cancel();
    }
  }, [watermark, reset, cancel]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).muxjs = muxjs;
    }

    shaka.polyfill.installAll();

    if (!uiRef.current) {
      return;
    }

    managerRef.current = new ShakaOfflineManager(
      videoRef.current,
      uiRef.current
    );

    const init = async () => {
      controller.current = await managerRef.current.init();

      const controls = managerRef.current.getUi().getControls();
      const container = controls.getControlsContainer() as HTMLElement;

      const overflow = container.getElementsByClassName(
        'shaka-overflow-menu-button'
      );

      if (overflow && overflow.length > 0 && overflow.item(0)) {
        overflow.item(0)!.innerHTML = 'settings';
      }

      setReady(true);
    };

    init();
  }, []);

  const onError = useCallback(
    (e: any) => {
      captureException(new Error(e), { user, event: playing });
      console.log('playerError', e);
    },
    [user, playing]
  );

  const onPlay = useCallback(
    (e: any) => {
      if (!managerRef.current || !videos) {
        return;
      }

      checkWatermark();
    },
    [checkWatermark, videos]
  );

  useEffect(() => {
    const video = videoRef.current;
    video?.addEventListener('play', onPlay);
    managerRef.current?.addEventListener('error', onError);

    return () => {
      managerRef.current?.removeEventListener('error', onError);
      video?.removeEventListener('play', onPlay);
    };
  }, [managerRef, videoRef, onError, onPlay]);

  useEffect(() => {
    if (!playing || !ready || !user) {
      return;
    }

    const { player } = controller.current;
    const eventVideo = videos.find(
      (item: any) => item.appMetadata.id === playing
    ) as any;

    if (!eventVideo) {
      return;
    }

    player.load(eventVideo.offlineUri);
  }, [ready, user, playing, videos]);

  React.useImperativeHandle(ref, () => ({
    get player() {
      return controller.current.player;
    },
    get ui() {
      return controller.current.ui;
    },
    get videoElement() {
      return controller.current.videoElement;
    },
  }));

  return (
    <>
      <Box
        ref={uiRef}
        className="kinema-theme"
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <video
          ref={videoRef}
          height="100%"
          width="100%"
          id="kinema-theme"
          autoPlay
        />
      </Box>
    </>
  );
});

export default Player;
