import React from 'react';
import { Flex, Text } from 'rebass/styled-components';

import { IconButton } from '@chakra-ui/react';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useGlobal } from '../hooks/useGlobal';
import ConfirmModal from './ConfirmModal';
import HelpModal from './HelpModal';
import InfoModal from './InfoModal';

const Footer: React.FC = () => {
  const { authenticated, logout, network } = useGlobal();

  return (
    <Flex
      flex={1}
      sx={{ position: 'fixed', bottom: 0, zIndex: 5, height: 72 }}
      py={3}
      px={4}
      bg="#111"
      width={1}
      alignItems="center"
    >
      <Flex flex={1} fontSize={1} color="#5b5d5f">
        v0.5.5
      </Flex>
      <Flex>
        {authenticated && (
          <>
            <Flex mr={4} alignItems="center">
              <Text mr={2} color="#555">
                {network.online ? '' : 'offline mode active'}
              </Text>
            </Flex>
            <Flex mr={2}>
              <InfoModal />
              <HelpModal />
            </Flex>
            <ConfirmModal
              message={`Are you sure you want to logout?`}
              activator={
                <IconButton
                  aria-label="Sign Out"
                  icon={
                    <FontAwesomeIcon icon={faSignOut} size="lg" fixedWidth />
                  }
                  sx={{ bg: 'transparent' }}
                />
              }
              onConfirm={logout}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default Footer;
