import './static/material.css';
import './static/shaka.css';
import 'focus-visible/dist/focus-visible';

import React, { useEffect, useState } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';

import { GlobalProvider } from './hooks/useGlobal';
import { ShakaProvider } from './hooks/useShaka';
import createClient from './lib/apolloClient';
import Dashboard from './screens/Dashboard';
import theme from './styles/theme';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: RegolaPro, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    font-weight: 400;
    background: #181819;
    color: #aaa;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overscroll-behavior-y: none;
    
    a {
      text-decoration: none;
      color: inherit;
    }

    #root {
      height: 100%;
    }
  }
`;

const App = () => {
  const [client, setClient] = useState<ApolloClient<
    NormalizedCacheObject
  > | null>(null);

  useEffect(() => {
    const init = async () => {
      setClient(await createClient());
    };

    init();
  }, []);

  if (!client) {
    return null;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <GlobalProvider>
            <ShakaProvider>
              <Dashboard />
            </ShakaProvider>
          </GlobalProvider>
        </ApolloProvider>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
};

export default App;
