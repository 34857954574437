import React from 'react';
import styled from 'styled-components';
import { height } from 'styled-system';

const StyledLogo = styled.div`
  ${height}
`;

const Logo: React.FC<any> = (props: any) => {
  return (
    <StyledLogo {...props}>
      <svg
        data-name="Kinema Logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 230 30.55"
        height="100%"
      >
        <defs>
          <clipPath id="prefix__a">
            <path fill="none" d="M0 0h230v30.55H0z" />
          </clipPath>
        </defs>
        <g clipPath="url(#prefix__a)">
          <path d="M112.08 30.55h-7.36L87 10.72v19.83h-8.14V0h8.34L104 18.8V0h8.12v30.55zM145.1 0v7.06h-21.53v4.55h19.1v6.9h-19.1v5h21.53v7.06h-29.7V0zM165.46 30.55l-9.27-22v22h-8.13V0h12.43l8.42 20.34L177.33 0h12.25v30.55h-8.08V8.6l-9.32 22zM215 0l15 30.55h-9.15l-2.76-5.75h-16l-2.77 5.75h-8.85L205.45 0zm-9.7 18.12h9.58l-4.77-10zM59.57 0L45.51 10.87V0H0v30.55h45.51V19.67l14.06 10.88h16V0zM51 15.27L67.2 3v24.55z" />
        </g>
      </svg>
    </StyledLogo>
  );
};

export default Logo;
