import { extendTheme } from '@chakra-ui/react';

const Button = {
  baseStyle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: 'sm',
    bg: '#000',
  },
  variants: {
    solid: {
      color: '#5b5d5f',
      bg: '#2b2c2d',
      _hover: {
        color: '#a3a3a6',
        bg: '#2b2c2d',
      },
      _active: {
        color: '#a3a3a6',
        bg: '#2b2c2d',
      },
    },
  },
};

const Modal = {
  baseStyle: {
    dialog: {
      bg: '#2b2c2d',
      borderRadius: 'sm',
    },
  },
};

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: 'RegolaPro, sans-serif',
        fontWeight: 400,
      },
    },
  },
  radii: {
    none: '0',
    sm: '0.15rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  components: {
    Button,
    Modal,
  },
});

export default theme;
