import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect, useState } from 'react';

const useAnalytics = () => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  const [writeKey] = useState(process.env.REACT_APP_SEGMENT_WRITE_KEY || '');

  useEffect(() => {
    const loadAnalytics = async () => {
      if (!writeKey) {
        return;
      }
      let [response] = await AnalyticsBrowser.load({ writeKey });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [writeKey]);

  return {
    analytics,
  };
};

export default useAnalytics;
