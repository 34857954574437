import React from 'react';
import { Flex } from 'rebass/styled-components';

import { useMyOfflineEventsQuery } from '../graphql/types';
import EventCard from './EventCard';
import SampleCard from './SampleEvent';
import SpinLoader from './SpinLoader';

const Events: React.FC = () => {
  const { data, loading } = useMyOfflineEventsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      take: 100,
    },
  });

  if (loading) {
    return (
      <>
        <Flex id="container" flex={1} flexDirection="column">
          <Flex justifyContent="center" alignItems="center" flex={1}>
            <SpinLoader primaryColor="#353537" secondaryColor="#5b5b5f" />
          </Flex>
        </Flex>
      </>
    );
  }

  return (
    <Flex flex={1} p={4}>
      <Flex
        flexDirection="column"
        sx={{
          alignSelf: 'flex-start',
          width: '100%',
          display: 'grid !important',
          gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
          gridGap: '2em',
        }}
      >
        {data &&
          data?.myOfflineEvents.items.map((item) => (
            <EventCard key={item.id} event={item} />
          ))}
        <SampleCard />
      </Flex>
    </Flex>
  );
};

export default Events;
