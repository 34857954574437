import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNetworkState } from 'react-use';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import {
  faArrowToBottom,
  faDoNotEnter,
  faPause,
  faPlay,
  faTrashAlt,
  faWifiSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IncognitoNotSupportedModal } from './IncognitoNotSupportedModal';
import { EventDetailFragment } from '../graphql/types';
import { getAnalyticsFromEvent } from '../helpers/analytics';
import useAnalytics from '../hooks/useAnalytics';
import { useGlobal } from '../hooks/useGlobal';
import { ShakaStatus, useShaka } from '../hooks/useShaka';
import { captureException } from '../lib/sentry';
import ConfirmModal from './ConfirmModal';

export enum EventStatus {
  Ready = 'Ready to play',
  Downloading = 'Downloading',
  Pending = 'Available soon',
  Available = 'Available for download',
  Unavailable = 'Unavailable',
  Removing = 'Please wait',
  Playing = 'Playing',
}

/**
 * Returns the string to show next to the status indicator.
 */
const getEventStatusText = (
  eventStatus: EventStatus,
  statusMessage: string
): string => {
  if (!statusMessage) {
    return eventStatus;
  }

  if (eventStatus === EventStatus.Pending) {
    return statusMessage;
  }

  return `${eventStatus} - ${statusMessage}`;
};

const Indicator = styled<any>(Box)`
  border-radius: 50%;
  height: 14px;
  width: 14px;
  transition: background 0.25s ease-in-out;
  background-color: ${(props) => {
    if (
      props.status === EventStatus.Ready ||
      props.status === EventStatus.Playing
    ) {
      return '#36a23e';
    } else if (props.status === EventStatus.Available) {
      return '#3e36a2';
    } else if (props.status === EventStatus.Downloading) {
      return '#F9D341';
    } else if (props.status === EventStatus.Pending) {
      return '#F9D341';
    } else {
      return '#666666';
    }
  }};
`;

const EventCard: React.FC<{ event: EventDetailFragment }> = ({ event }) => {
  const { online } = useNetworkState();
  const [video, setVideo] = useState<any | null>({});
  const [remotePlaying, setRemotePlaying] = useState(false);
  const [eventStatus, setEventStatus] = useState<EventStatus>(
    EventStatus.Unavailable
  );
  const [statusMessage, setStatusMessage] = useState<string>('');
  const { isIncognito, user } = useGlobal();
  const { analytics } = useAnalytics();
  const { isOpen, onClose, onOpen } = useDisclosure();
  
  const {
    videos,
    status,
    remove,
    download,
    playing,
    selected,
    setPlaying,
    ready,
    shakaRef,
  } = useShaka();

  useEffect(() => {
    const eventVideo = videos.find(
      (item: any) => item.appMetadata.id === event.id
    ) as any;

    const start = moment(event.start).utc();
    const now = moment().utc();
    const available = moment(event.start).utc().subtract(72, 'hours');
    const expires = moment(event.start).utc().add(24, 'hours');
    const videoExpires =
      eventVideo && eventVideo.expiration
        ? moment(eventVideo.expiration).utc()
        : null;

    const checkExpired = () => {
      if (!videoExpires) {
        return;
      }

      const datetime = moment(eventVideo.expiration).format(
        'MMM D, yyyy [at] h:mm a'
      );

      if (now.isSameOrBefore(videoExpires)) {
        setStatusMessage(`expires on ${datetime}`);
        setEventStatus(EventStatus.Ready);
      } else {
        setStatusMessage(`expired on ${datetime}`);
        setEventStatus(EventStatus.Unavailable);
      }
    };

    if (now.isSameOrAfter(available) && now.isSameOrBefore(expires)) {
      if (!eventVideo || !eventVideo.offlineUri) {
        setStatusMessage('');
        setEventStatus(EventStatus.Available);
      }
    } else if (now.isBefore(start)) {
      setEventStatus(EventStatus.Pending);
      setStatusMessage(
        `Available for download on ${available.local().format(
          'MMM D, yyyy [at] h:mm a'
        )}`
      );
    } else if (eventVideo) {
      setStatusMessage('');
      setEventStatus(EventStatus.Ready);
    } else {
      setStatusMessage('');
      setEventStatus(EventStatus.Unavailable);
    }

    checkExpired();

    setVideo(eventVideo);
  }, [event, videos, selected]);

  useEffect(() => {
    if (status === ShakaStatus.Removing) {
      setEventStatus(EventStatus.Removing);
    }
  }, [status]);

  const handleRemove = useCallback(async () => {
    if (remotePlaying) {
      shakaRef.current?.videoElement.pause();
    }

    setRemotePlaying(false);
    await remove(video.offlineUri, event.id);
  }, [video, shakaRef, event, remove, remotePlaying, setRemotePlaying]);
  const incognitoCheck = () => {
    console.log('isIncognito: ', isIncognito);
    if (isIncognito) {
      onOpen();
      return;
    }

    handleClick();
  };

  const handleOnCloseModal = () => {
    console.log('hit');
    onClose();
    handleClick();
  };

  const handleClick = useCallback(async () => {
    if (eventStatus === EventStatus.Available) {
      try {
        let downloadUrl: string;

        if (event.playlistId === 'v1' && event.project.downloadUrl) {
          downloadUrl = event.project.downloadUrl;
        } else if (event.playlistId) {
          downloadUrl = `${process.env.REACT_APP_VIDEO_STREAM_BASE_URL}/dash/${event.playlistId}/manifest.mpd`;
        } else {
          throw new Error('Unable to determine download URL');
        }

        analytics?.track('Download Started', {
          user,
          event: getAnalyticsFromEvent(event),
        });

        await download(downloadUrl, {
          id: event.id,
          eventId: event.id,
          title: event.project.film.title,
          downloaded: new Date(),
          start: event.start,
          end: event.end,
        });

        analytics?.track('Download Finished', {
          user,
          event: getAnalyticsFromEvent(event),
        });
      } catch (error) {
        captureException(error, { user, event: getAnalyticsFromEvent(event) });
        console.log(error);
      }
    } else if (eventStatus === EventStatus.Ready) {
      setPlaying(event.id);

      if (remotePlaying) {
        shakaRef.current?.videoElement.pause();
      }
    }
  }, [
    analytics,
    event,
    eventStatus,
    download,
    setPlaying,
    remotePlaying,
    shakaRef,
    user,
  ]);

  const handleReadyClick = useCallback(() => {
    if (remotePlaying) {
      shakaRef.current?.videoElement.pause();
      setRemotePlaying(false);
    } else {
      setPlaying(event.id);
      shakaRef.current?.videoElement.play();
      setRemotePlaying(true);
    }
  }, [setPlaying, setRemotePlaying, event, remotePlaying, shakaRef]);

  const onPlay = useCallback(() => {
    if (playing === event.id) {
      setRemotePlaying(true);
      setEventStatus(EventStatus.Playing);
    }
  }, [playing, event]);

  const onPause = useCallback(() => {
    if (playing === event.id) {
      setRemotePlaying(false);
      setEventStatus(EventStatus.Ready);
    }
  }, [playing, event]);

  useEffect(() => {
    if (!shakaRef.current || !shakaRef.current.videoElement) {
      return;
    }

    const video = shakaRef.current.videoElement;

    video.addEventListener('play', onPlay);
    video.addEventListener('pause', onPause);

    return () => {
      video.removeEventListener('play', onPlay);
      video.removeEventListener('pause', onPause);
    };
  }, [shakaRef, onPlay, onPause]);

  if (!ready) {
    return null;
  }

  return (
    <Flex flex={1} width={1} p={0} bg="#2b2c2d" variant="card">
      <Flex flex={1} flexDirection="column" p={3}>
        <Flex flex={1} alignItems="center">
          <Text
            fontSize={3}
            fontWeight={600}
            onClick={handleClick}
            color="#828487"
            sx={{
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              ':hover': { color: '#a3a3a6' },
            }}
          >
            {event.project.film.title}
          </Text>
          <Flex alignItems="center" justifyContent="flex-start" pl={3}>
            {online && video && video.offlineUri && (
              <>
                <ConfirmModal
                  message={`Are you sure you would like to delete?`}
                  activator={
                    <Flex
                      color="#5b5d5f"
                      sx={{
                        height: '100%',
                        width: 100,
                        ':hover': {
                          color: '#a3a3a6',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                    </Flex>
                  }
                  onConfirm={handleRemove}
                />
              </>
            )}
          </Flex>
        </Flex>
        <Box>
          <Text
            mb={4}
            color="#787a7d"
            fontSize={2}
            fontWeight={400}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {moment(event.start).format('MMM D, yyyy [at] h:mm a')}
          </Text>
          <Flex flex={1} pt={2} alignItems="center">
            <Flex>
              <Indicator status={eventStatus} mr={3} />
            </Flex>
            <Text
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textTransform: 'uppercase',
                fontSize: 1,
                fontWeight: 500,
                lineHeight: 1,
                marginTop: '2px',
                color: '#787a7d',
              }}
            >
              {getEventStatusText(eventStatus, statusMessage)}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex
        sx={{
          position: 'relative',
          alignItems: 'center',
          borderLeft: '1px solid #3e4041',
        }}
      >
        {online && eventStatus === EventStatus.Available && (
          <Flex
            alignItems="center"
            justifyContent="center"
            onClick={incognitoCheck}
            color="#5b5d5f"
            px={4}
            py={2}
            sx={{
              height: '100%',
              width: 100,
              ':hover': {
                background: '#3e4041',
                color: '#a3a3a6',
                cursor: 'pointer',
              },
            }}
          >
            <FontAwesomeIcon icon={faArrowToBottom} size="2x" />
          </Flex>
        )}
        {(eventStatus === EventStatus.Unavailable ||
          eventStatus === EventStatus.Downloading ||
          eventStatus === EventStatus.Removing ||
          eventStatus === EventStatus.Pending) && (
          <Flex
            alignItems="center"
            justifyContent="center"
            color="#3e4041"
            px={4}
            py={2}
            sx={{
              height: '100%',
              width: 100,
            }}
          >
            <FontAwesomeIcon icon={faDoNotEnter} size="2x" />
          </Flex>
        )}

        {(eventStatus === EventStatus.Ready ||
          eventStatus === EventStatus.Playing) &&
          video &&
          video.offlineUri && (
            <>
              <Flex
                alignItems="center"
                justifyContent="center"
                onClick={handleReadyClick}
                color="#5b5d5f"
                px={4}
                py={2}
                sx={{
                  height: '100%',
                  width: 100,
                  ':hover': {
                    background: '#3e4041',
                    color: '#a3a3a6',
                    cursor: 'pointer',
                  },
                }}
              >
                <FontAwesomeIcon
                  icon={remotePlaying ? faPause : faPlay}
                  size="2x"
                />
              </Flex>
            </>
          )}
        {!online &&
          (eventStatus === EventStatus.Pending ||
            eventStatus === EventStatus.Available) && (
            <Flex
              alignItems="center"
              justifyContent="center"
              color="#5b5d5f"
              px={4}
              py={2}
              sx={{
                height: '100%',
                width: 100,
              }}
            >
              <FontAwesomeIcon icon={faWifiSlash} size="2x" />
            </Flex>
          )}
      </Flex>
      <IncognitoNotSupportedModal
        isOpen={isOpen}
        onClose={onClose}
        download={handleOnCloseModal}
      />
    </Flex>
  );
};

export default EventCard;
