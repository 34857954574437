import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Text } from 'rebass/styled-components';

interface IncognitoNotSupportedModalProps {
  isOpen: boolean;
  onClose: () => void;
  download: () => void;
}

export const IncognitoNotSupportedModal: FC<IncognitoNotSupportedModalProps> =
  ({ download, isOpen, onClose }) => {
    return (
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Incognito Mode Detected</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={3} fontWeight={400} lineHeight={1.6}>
              We detected that your browser is in Incognito or Guest mode. Our
              Offline Player doesn't support these modes.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="ghost" onClick={download}>Proceed Anyways</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
