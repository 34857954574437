import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';

import { Portal } from '@chakra-ui/react';

import Events from '../components/Events';
import Player from '../components/Player';
import { useShaka } from '../hooks/useShaka';
import DownloadingOverlay from './DownloadingOverlay';
import Logo from './Logo';
import LogoContainer from './LogoContainer';
import { ErrorOverlay } from './ErrorOverlay';

const Main: React.FC = () => {
  const { playing, shakaRef } = useShaka();

  return (
    <>
      <LogoContainer flexDirection="column">
        <Flex
          flexDirection="column"
          bg="#000"
          className={!playing ? 'bg-container' : ''}
          width={1}
          sx={{ position: 'fixed', zIndex: 5, maxHeight: 540 }}
        >
          <Flex
            flex={1}
            sx={{ maxWidth: 720, width: '100%', maxHeight: 540 }}
            alignSelf="center"
          >
            <Box
              sx={{
                paddingTop: '56.25%',
                position: 'relative',
                width: '100%',
                maxHeight: 540,
              }}
            >
              <Flex
                flex={1}
                sx={{
                  position: 'absolute',
                  top: playing ? 0 : -9999,
                  height: '100%',
                  width: '100%',
                  display: playing ? 'flex' : 'none',
                }}
              >
                <Player ref={shakaRef} />
              </Flex>
              {!playing && (
                <Flex
                  flex={1}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    fill: '#999',
                  }}
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Logo height={['10%', '10%', '10%']} />
                  <Text
                    mt={3}
                    fontSize={['12px', 2, 2]}
                    fontWeight={600}
                    color="#5b5d5f"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Offline Cinema Player
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>
        </Flex>
        <Flex
          flex={1}
          flexDirection="column"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            ':after': { content: '""', paddingBottom: 86 },
          }}
        >
          <Flex
            flex={1}
            sx={{ position: 'relative', maxWidth: 720, maxHeight: 405 }}
          >
            <Flex
              sx={{ paddingTop: '56.25%', maxWidth: 720, maxHeight: 405 }}
            />
          </Flex>
          <Events />
        </Flex>
      </LogoContainer>
      <Portal>
        <DownloadingOverlay />
        <ErrorOverlay />
      </Portal>
    </>
  );
};

export default Main;
