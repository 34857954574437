import shaka from 'shaka-player/dist/shaka-player.ui';

import { WatermarkDisplayer } from './WatermarkDisplayer';

export class ShakaOfflineManager {
  private player: any;
  private ui: any;
  private containerElement: HTMLElement;
  private videoElement: HTMLMediaElement;
  private watermarkDisplayer: WatermarkDisplayer | null;
  private offlineStorage: any;

  /**
   * @param {HTMLMediaElement} videoElement
   * @param {HTMLElement} containerElement
   */
  constructor(videoElement: HTMLMediaElement, containerElement: HTMLElement) {
    shaka.polyfill.installAll();

    if (!shaka.Player.isBrowserSupported()) {
      throw new Error('Your browser is not supported');
    }

    this.videoElement = videoElement;
    this.containerElement = containerElement;
    this.watermarkDisplayer = null;

    this.shakaErrorHandler = this.shakaErrorHandler.bind(this);
  }

  public async init(playerConfig?: any) {
    this.player = new shaka.Player(this.videoElement);
    this.player.configure({
      drm: {
        servers: {
          'com.widevine.alpha':
            'https://lic.drmtoday.com/license-proxy-widevine/cenc/',
        },
        advanced: {
          'com.widevine.alpha': {
            videoRobustness: 'SW_SECURE_CRYPTO',
            audioRobustness: 'SW_SECURE_CRYPTO',
          },
        },
      },
      streaming: {
        jumpLargeGaps: true,
        bufferingGoal: 30,
      },
    });

    this.player.configure(
      'drm.initDataTransform',
      (initData: any, initDataType: any, drmInfo: any) => {
        if (initDataType !== 'skd') {
          return initData;
        }

        const uriString = shaka.util.StringUtils.fromBytesAutoDetect(
          initData
        ).replace('skd://drmtoday?', '');
        const params = new URLSearchParams(uriString);

        return shaka.util.FairPlayUtils.initDataTransform(
          initData,
          params.get('assetId'),
          drmInfo.serverCertificate
        );
      }
    );

    this.offlineStorage = new shaka.offline.Storage(this.player);
    this.offlineStorage
      .getNetworkingEngine()
      .registerResponseFilter((type: any, response: any) => {
        if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
          return;
        }

        const wrappedString = shaka.util.StringUtils.fromUTF8(response.data);
        const wrapped = JSON.parse(wrappedString);
        const { license } = wrapped;
        response.data = shaka.util.Uint8ArrayUtils.fromBase64(license);
      });

    this.offlineStorage
      .getNetworkingEngine()
      .addEventListener('error', (error: any) => {
        console.log(error);
      });

    this.ui = new shaka.ui.Overlay(
      this.player,
      this.containerElement,
      this.videoElement
    );

    const config = {
      addBigPlayButton: false,
      addSeekBar: true,
      overflowMenuButtons: ['captions', 'quality', 'language'],
      ...playerConfig,
    };

    this.ui.configure(config);

    this.watermarkDisplayer = new WatermarkDisplayer(
      this.videoElement,
      this.containerElement
    );
    // this.player.addEventListener('error', this.shakaErrorHandler);

    return { player: this.player, videoElement: this.videoElement, offlineStorage: this.offlineStorage };
  }

  /**
   * Sets Visual Watermark
   *
   * @public
   * @param lines
   * @return
   */
  public setVisualWatermark(lines: string[]) {
    if (lines.length === 0) {
      this.watermarkDisplayer?.setTextVisibility(false);
      return;
    }

    this.watermarkDisplayer?.setText(lines);
    this.watermarkDisplayer?.setTextVisibility(true);
  }

  /**
   * Adds request filter to shaka offline
   *
   * @public
   * @param requestFilter
   * @return
   */
  public registerRequestFilter(requestFilter: any) {
    this.offlineStorage
      .getNetworkingEngine()
      .registerRequestFilter(requestFilter);
  }

  /**
   * Sets AppData for CastProxy
   *
   * @public
   * @param data
   * @return
   */
  public setAppData(data: any) {
    this.ui.getControls().getCastProxy().setAppData(data);
  }

  /**
   * Loads manifest
   *
   * @public
   * @param manifestUri
   * @return
   */
  public async load(manifestUri: string) {
    return await this.player.load(manifestUri);
  }

  /**
   * Add an event listener to shaka player
   */
  public addEventListener(type: any, listener: any, options?: any) {
    this.player.addEventListener(type, listener, options);
  }

  /**
   * Remove an event listener to shaka player
   */
  public removeEventListener(type: any, listener: any, options?: any) {
    this.player.removeEventListener(type, listener, options);
  }

  /**
   * [destructor]
   *
   * @return
   */
  public destroy() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }

    if (this.ui) {
      this.ui.destroy();
      this.ui = null;
    }

    if (this.watermarkDisplayer) {
      this.watermarkDisplayer.destroy();
      this.watermarkDisplayer = null;
    }
  }

  public getPlayer() {
    return this.player;
  }


  public getOfflineStorage() {
    return this.offlineStorage;
  }

  public getUi() {
    return this.ui;
  }

  /**
   * Handles errors thrown by shaka player.
   * See https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
   *
   * @private
   * @param error
   * @return
   */
  private shakaErrorHandler(error: any) {
    console.log('[ShakaManager][error]', error);
  }
}
