import * as Sentry from '@sentry/react';

const captureMessage = (
  msg: any,
  ctx?: any,
  level?: Sentry.SeverityLevel
): string | undefined => {
  Sentry.configureScope((scope): void => {
    if (ctx.user) {
      scope.setUser(ctx.user);
    }
  });

  return Sentry.captureMessage(msg, level);
};

const captureException = (err: any, ctx?: any): string | undefined => {
  Sentry.configureScope((scope): void => {
    if (err.message) {
      scope.setFingerprint([err.message]);
    }

    if (err.statusCode) {
      scope.setExtra('statusCode', err.statusCode);
    }
    if (ctx.user) {
      scope.setUser(ctx.user);
    }
    if (ctx.event) {
      scope.setExtra('Event', ctx.event);
    }
  });

  if ('isAxiosError' in err) {
    return Sentry.captureException(err.toJSON());
  }

  return Sentry.captureException(err);
};

export { captureException, captureMessage, Sentry };
