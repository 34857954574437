import { filesize } from 'filesize';
import { useCallback, useEffect } from 'react';

import {
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { ShakaStatus, useShaka } from '../hooks/useShaka';

const DownloadingOverlay = () => {
  const { status, progress } = useShaka();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (status === ShakaStatus.Downloading && !isOpen) {
      onOpen();
    }

    if (status !== ShakaStatus.Downloading && isOpen) {
      onClose();
    }
  }, [isOpen, status, onOpen, onClose]);

  const checkForDownload = useCallback(() => {
    return Boolean(status === ShakaStatus.Downloading);
  }, [status]);

  useEffect(() => {
    const eventHandler = (e: BeforeUnloadEvent): boolean => {
      if (checkForDownload()) {
        e.preventDefault();
        e.returnValue = 'You sure you want to cancel download?';
        return true;
      }

      return false;
    };

    window.addEventListener('beforeunload', eventHandler);

    return () => {
      window.removeEventListener('beforeunload', eventHandler);
    };
  }, [checkForDownload]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        preserveScrollBarGap={true}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          sx={{
            height: 300,
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Flex
            flex={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress
              value={Math.round(progress.percent * 100)}
              color="green.400"
              size={250}
            >
              <CircularProgressLabel
                sx={{
                  fontVariantNumeric: 'tabular-nums',
                  fontVariant: 'ta',
                  lineHeight: 1,
                  fontSize: '0.1em',
                  paddingTop: '5%',
                }}
              >
                {Math.round(progress.percent * 100)}%
                <Flex mb={2} mt={2} justify="center">
                  <Flex
                    w="60px"
                    justify="space-between"
                    pt={2}
                    borderTop={1}
                    borderTopStyle="solid"
                    borderTopColor="#555"
                  >
                    <Flex fontSize="12px" maxW="50px" overflow="hidden">
                      {
                        (
                          filesize(progress.downloaded, {
                            output: 'array',
                            round: 0,
                          }) as any[]
                        )[0]
                      }
                    </Flex>
                    <Flex fontSize="12px">
                      {
                        (
                          filesize(progress.downloaded, {
                            output: 'array',
                            round: 0,
                          }) as any[]
                        )[1]
                      }
                    </Flex>
                  </Flex>
                </Flex>
                <Flex justify="center">
                  <Flex w="60px" justify="space-between">
                    <Flex fontSize="12px" maxW="50px" overflow="hidden">
                      {
                        (
                          filesize(progress.total, {
                            output: 'array',
                            round: 0,
                          }) as any[]
                        )[0]
                      }
                    </Flex>
                    <Flex fontSize="12px">
                      {
                        (
                          filesize(progress.total, {
                            output: 'array',
                            round: 0,
                          }) as any[]
                        )[1]
                      }
                    </Flex>
                  </Flex>
                </Flex>
              </CircularProgressLabel>
            </CircularProgress>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DownloadingOverlay;
