import React from 'react';

import {
  Box,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  faArrowToBottom,
  faClock,
  faExternalLink,
  faPlayCircle,
  faQuestion,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HelpModal: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Help"
        onClick={onOpen}
        icon={<FontAwesomeIcon icon={faQuestion} size="lg" fixedWidth />}
        sx={{ bg: 'transparent' }}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        preserveScrollBarGap={true}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>How this works</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              spacing={5}
              divider={<StackDivider borderColor="whiteAlpha.400" />}
            >
              <HStack w="full" spacing={5}>
                <Box color="whiteAlpha.500">
                  <FontAwesomeIcon icon={faClock} size="3x" fixedWidth />
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    Availability
                  </Text>
                  <Text fontSize="sm" lineHeight={1.6}>
                    72 hours before your screening the movie will be available
                    for download.
                  </Text>
                </Box>
              </HStack>
              <HStack w="full" spacing={5}>
                <Box color="gray">
                  <FontAwesomeIcon
                    icon={faArrowToBottom}
                    size="3x"
                    fixedWidth
                  />
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    Download
                  </Text>
                  <Text lineHeight={1.6} mb={2} fontSize="sm">
                    Click the download icon to start. After downloading, the
                    movie will remain available within the Offline Cinema Player
                    with or without an internet connection.
                  </Text>
                  <Text color="whiteAlpha.500" lineHeight={1.6} fontSize="sm">
                    Downloading may take some time and will vary based on your
                    internet connection speed.
                  </Text>
                </Box>
              </HStack>
              <HStack w="full" spacing={5}>
                <Box color="gray">
                  <FontAwesomeIcon icon={faPlayCircle} size="3x" fixedWidth />
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    Sample
                  </Text>
                  <Text lineHeight={1.6} fontSize="sm">
                    Try it out now with the sample screening that is available
                    anytime.
                  </Text>
                </Box>
              </HStack>
              <HStack w="full" spacing={5}>
                <Box color="gray">
                  <FontAwesomeIcon icon={faQuestion} size="3x" fixedWidth />
                </Box>
                <Box>
                  <Text lineHeight={1.6} fontSize="sm">
                    <Text fontWeight="bold" mb={2}>
                      More Help and Tips
                    </Text>
                    <Link
                      href="https://support.kinema.com/en/articles/5524022-in-person-kinema-best-practices-guide?utm_source=kinema&utm_medium=offline_player&utm_campaign=help"
                      target="_blank"
                      color="purple.500"
                    >
                      Support <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HelpModal;
