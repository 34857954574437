import './static/controls.css';
import './static/gilroy.css';
import './static/regola.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { ChakraProvider } from '@chakra-ui/react';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import chakraTheme from './styles/chakra';

Sentry.init({
  dsn: `https://${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [new BrowserTracing()],
});

FullStory.init({
  orgId: '1922QZ',
  devMode: process.env.NODE_ENV !== 'production',
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={chakraTheme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (navigator && !navigator.onLine) {
      return;
    }

    console.log('Update available');

    if (registration && registration.waiting) {
      const confirmation = window.confirm(
        'New version available!  Ready to update?'
      );

      if (!confirmation) {
        return;
      }

      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});
