import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';
import shaka from 'shaka-player';

import { useDrmTokenOfflineMutation } from '../graphql/types';
import { ShakaErrorCode } from '../lib/shaka/ShakaError';

const { StringUtils } = shaka.util;
const { Uint8ArrayUtils } = shaka.util;

const initialProgress = { percent: 0, downloaded: 0, total: 0 };

export enum ShakaStatus {
  Idle,
  Downloading,
  Removing,
  Playing,
}

export type CurrentError = ShakaErrorCode | string | null;

interface ShakaContextProps {
  download: (url: string, metadata: any) => Promise<any>;
  remove: (url: string, eventId: string) => Promise<void>;
  refreshList: () => Promise<void>;
  destroy: () => Promise<void>;
  setSelected: (value: string) => void;
  setPlaying: (value: string) => void;
  videos: [];
  selected: string | undefined;
  playing: string;
  progress: { percent: number; downloaded: number; total: number };
  status: ShakaStatus;
  ready: boolean;
  shakaRef: any;
  /**
   * To be shown in `ErrorOverlay`
   */
  currentError: CurrentError;
  setCurrentError: (error: CurrentError) => void;
}

const ShakaContext = React.createContext<ShakaContextProps>({
  download: async () => Promise.resolve() as any,
  remove: async () => Promise.resolve() as any,
  destroy: async () => Promise.resolve() as any,
  refreshList: async () => Promise.resolve(),
  setSelected: () => {},
  setPlaying: () => {},
  videos: [],
  selected: '',
  playing: '',
  progress: initialProgress,
  status: ShakaStatus.Idle,
  ready: false,
  shakaRef: null,
  currentError: null,
  setCurrentError: () => {},
});

export const ShakaProvider: FC = ({ children }) => {
  const [storage, setStorage] = useState<any>(null);
  const [videos, setVideos] = useState<any>([]);
  const [progress, setProgress] =
    useState<ShakaContextProps['progress']>(initialProgress);
  const [selected, setSelected, removeSelected] = useLocalStorage<string>(
    'selected',
    ''
  );
  const [playing, setPlaying] = useState<string>('');
  const [ready, setReady] = useState(false);
  const [status, setStatus] = useState<ShakaStatus>(ShakaStatus.Idle);
  const [currentError, setCurrentError] = useState<CurrentError>(null);
  const playerRef = useRef(null);
  const shakaRef = useRef(null);
  const controller = useRef<{ player: any; playerElement: any }>({
    player: null,
    playerElement: playerRef,
  });

  const [drmToken] = useDrmTokenOfflineMutation();

  const setDownloadProgress = useCallback(
    (
      content: {
        duration: number;
        size: number;
        tracks: Array<{ bandwidth: number; [key: string]: unknown }>;
        [key: string]: unknown;
      },
      progress: number
    ) => {
      const { duration, size, tracks } = content;

      let totalEstimate = 0;
      for (const track of tracks) {
        const trackSize = (track.bandwidth * duration) / 8;
        totalEstimate += trackSize;
      }

      setProgress({
        percent: progress,
        downloaded: size,
        total: totalEstimate,
      });
    },
    []
  );

  type Track = {
    bandwidth: number;
    type: string;
    [key: string]: unknown;
  };

  const selectTracks = (tracks: Track[]) => {
    // Use the media track with the highest bandwidth
    const mediaTrack = tracks
      .filter((track) => track.type === 'variant')
      .sort((a, b) => a.bandwidth - b.bandwidth)
      .pop();

    // Include all subtitles/captions
    const textTracks = tracks.filter((track) => track.type === 'text');

    return [mediaTrack, ...textTracks];
  };

  const [config] = useState({
    offline: {
      usePersistentLicense: true,
      trackSelectionCallback: selectTracks,
      progressCallback: setDownloadProgress,
    },
    drm: {
      servers: {
        'com.widevine.alpha':
          'https://lic.drmtoday.com/license-proxy-widevine/cenc/',
      },
      advanced: {
        'com.widevine.alpha': {
          persistentStateRequired: true,
          videoRobustness: 'SW_SECURE_CRYPTO',
          audioRobustness: 'SW_SECURE_CRYPTO',
        },
      },
    },
  });

  useEffect(() => {
    shaka.polyfill.installAll();
    const player = new shaka.Player(playerRef.current);
    controller.current = { player, playerElement: playerRef.current };
  }, []);

  useEffect(() => {
    const { player } = controller.current;

    if (!player) {
      return;
    }

    player.configure(config);
    const offlineStorage = new shaka.offline.Storage(player);

    offlineStorage
      .getNetworkingEngine()
      .registerRequestFilter(async (type: any, request: any) => {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          const eventId = window.localStorage.getItem('selected');

          const { data } = await drmToken({
            variables: {
              sessionId: request.sessionId,
              eventId: eventId?.replaceAll('"', '') || '',
            },
          });

          request.headers['x-dt-auth-token'] = data?.drmTokenOffline.token;
        }
      });

    offlineStorage
      .getNetworkingEngine()
      .registerResponseFilter((type: any, response: any) => {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          const wrappedString = StringUtils.fromUTF8(response.data);
          const wrapped = JSON.parse(wrappedString);
          const { license } = wrapped;
          response.data = Uint8ArrayUtils.fromBase64(license);
        }
      });

    offlineStorage
      .getNetworkingEngine()
      .addEventListener('error', (error: any) => {
        console.log(error);
      });

    setStorage(offlineStorage);
  }, [config, drmToken, controller]);

  useEffect(() => {
    if (!storage) {
      return;
    }

    const initVideos = async () => {
      const list = await storage.list();

      setVideos(list);
      setReady(true);
    };

    initVideos();
  }, [storage]);

  const remove = useCallback(
    async (url: string, eventId: string) => {
      if (!storage) {
        return;
      }

      try {
        setStatus(ShakaStatus.Removing);
        setPlaying('');
        setSelected(eventId);

        await storage.remove(url);
        setVideos(await storage.list());
      } catch (error) {
        console.log(error);
      } finally {
        setStatus(ShakaStatus.Idle);
        setProgress(initialProgress);
        removeSelected();
      }
    },
    [storage, removeSelected, setSelected]
  );

  const download = useCallback(
    async (url: string, metadata: any): Promise<any> => {
      setStatus(ShakaStatus.Downloading);
      setSelected(metadata.id);

      await storage
        .store(url, metadata)
        .chain(
          async () => {
            const list = await storage.list();
            setVideos(list);
          },
          (error: any) => {
            console.log('download error: ', error);

            if (error === undefined) {
              // This can happen in some browsers when the actual available
              // storage space is lower than the estimate value reported by
              // `navigator.storage.estimate`
              setCurrentError(ShakaErrorCode.STORAGE_LIMIT_REACHED);
              throw new Error('There was a problem with the download');
            }

            if (error instanceof shaka.util.Error) {
              setCurrentError(error.code);
            } else {
              setCurrentError('There was a problem with the download.');
            }

            throw error;
          }
        )
        .finally(() => {
          removeSelected();
          setStatus(ShakaStatus.Idle);
        }).promise;
    },
    [storage, removeSelected, setSelected]
  );

  const destroy = useCallback(async () => {
    await storage.destroy();
  }, [storage]);

  const refreshList = useCallback(async () => {
    const list = await storage.list();

    setVideos(list);
  }, [storage]);

  return (
    <ShakaContext.Provider
      value={{
        videos,
        download,
        destroy,
        refreshList,
        ready,
        remove,
        selected,
        playing,
        setPlaying,
        setSelected,
        progress,
        status,
        shakaRef,
        currentError,
        setCurrentError,
      }}
    >
      {children}
    </ShakaContext.Provider>
  );
};

export const useShaka = () => {
  const context = React.useContext(ShakaContext);

  if (context === undefined) {
    throw new Error('no provider available');
  }

  return context;
};
