import { detectIncognito } from 'detectincognitojs';
import { useEffect, useState } from 'react';

export const useIncognito = () => {
  const [isIncognito, setIsIncognito] = useState<boolean | null>(null);

  useEffect(() => {
    let mounted = true;

    const isIncognitoFn = async () => {
      const result = await detectIncognito();
      if (mounted) {
        setIsIncognito(result.isPrivate);
      }
    };

    isIncognitoFn();

    return () => {
      mounted = false;
    };
  }, []);

  return { isIncognito };
};
