import { CachePersistor, PersistentStorage } from 'apollo3-cache-persist';
import { PersistedData } from 'apollo3-cache-persist/lib/types';

import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { TOKEN_KEY } from '../hooks/useGlobal';

class LocalStoragePersistedStorage
  implements PersistentStorage<PersistedData<NormalizedCacheObject>>
{
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
  }
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_KEY);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.replaceAll('"', '')}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions?.code === 'UNAUTHENTICATED') {
        window.localStorage.clear();
        sessionStorage.setItem('session-expired', '1');
        window.location.reload();
      }
    });
  }
});

async function createClient() {
  const cache = new InMemoryCache({});
  const persistor = new CachePersistor({
    storage: new LocalStoragePersistedStorage(),
    cache,
  });

  await persistor.restore();

  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache,
  });
}

export default createClient;
