import { EventDetailFragment } from '../graphql/types';

export const getAnalyticsFromEvent = (event: EventDetailFragment) => {
  return {
    id: event.id,
    title: event.title,
    slug: event.slug,
    start: event.start,
    status: event.status,
    project: event.project
      ? {
          id: event.project.id,
          title: event.project.title,
          film: event.project.film
            ? {
                id: event.project.film.id,
                title: event.project.film.title,
              }
            : null,
        }
      : null,
  };
};
