import deepmerge from 'deepmerge';

import preset from '@rebass/preset';

const theme = deepmerge(preset, {
  colors: {
    primary: '#28AFB0',
    black: '#000',
    white: '#FFFFFF',
    text: '#000',
    green: '#28AEB0',
    darkGreen: '#19647E',
    lightGray: '#E9E9E9',
    borderGray: '#C8C8C8',
    darkGray: '#A1A1A1',
    gray: '#D9D8D6',
    yellow: '#f4d35e',
    blue: '#28AFB0',
    lightTurqoise: '#43D1D2',
    turqoise: '#28AFB0',
    orange: '#EE964B',
    red: '#FE4A49',
    label: '#888',
  },
  bg: {
    default: '#FFFFFF',
    inactive: '#EEEEEE',
    disabled: '#A1A1A1',
  },
  brand: {
    secondary: '#28AEB0',
  },
  fonts: {
    body: 'RegolaPro, sans-serif',
  },
  shadows: {
    tooltip: '0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)',
  },
  buttons: {
    primary: {
      position: 'relative',
      color: '#000',
      background: '#999',
      fontSize: 2,
      fontWeight: 500,
      borderRadius: 2,
      padding: '8px 24px 6px',
      '&:hover': {
        cursor: 'pointer',
        background: '#ccc',
      },
      '&:focus': {
        outline: 'none',
      },
      '&:disabled': {
        color: '#666',
        '&:hover': {
          cursor: 'not-allowed',
        },
      },
    },
    secondary: {
      background: '#28AFB0',
    },
    outline: {
      position: 'relative',
      background: 'transparent',
      border: '1px solid',
      borderColor: 'gray',
      boxShadow: 'none',
      borderRadius: 'default',
      transition: 'background 0.2s ease-in-out, box-shadow 0.3s ease-in-out',
      '&:hover': {
        background: '#f4d35e',
        borderColor: '#f4d35e',
        cursor: 'pointer',
      },
    },
  },
  text: {
    placeholder: '#888',
  },
  forms: {
    label: {
      color: 'label',
      fontSize: 1,
      fontWeight: 600,
      textTransform: 'uppercase',
      mb: 1,
    },
    textarea: {
      color: '#444',
      borderColor: '#D9D8D6',
      background: '#FFFFFF',
      fontFamily: 'RegolaPro, sans-serif',
      padding: 18,
      fontSize: 16,
      lineHeight: 1.5,
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        color: '#ccc',
      },
    },
    input: {
      color: '#aaa',
      borderColor: '#444',
      background: 'transparent',
      fontFamily: 'RegolaPro, sans-serif',
      fontSize: 16,
      padding: 18,
      lineHeight: 1,
      marginBottom: 0,
      '&:focus': {
        outline: 'none',
        background: '#444',
      },
      '&::placeholder': {
        color: '#ccc',
      },
      '&::-webkit-input-placeholder': {
        color: '#ccc',
      },
      '&:disabled': {
        background: '#EEEEEE',
      },
    },
  },
  variants: {
    badge: {
      color: 'white',
    },
    divider: {
      border: 0,
      borderColor: '#ddd',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
  },
  radii: {
    default: 2,
  },
});

export default theme;
