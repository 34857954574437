import React from 'react';
import { Button, Flex, Text } from 'rebass/styled-components';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

interface ConfirmModalProps {
  message: string;
  negativeLabel?: string;
  postiveLabel?: string;
  activator: React.ReactNode;
  onConfirm: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  activator,
  message,
  negativeLabel,
  postiveLabel,
  onConfirm,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex flex={1} onClick={onOpen} sx={{ height: '100%' }}>
        {activator}
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        preserveScrollBarGap={true}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Text
              mt={3}
              fontSize={3}
              fontWeight={500}
              color="#9d9d9d"
            >
              {message}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              {negativeLabel || 'No'}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {postiveLabel || 'Yes'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmModal;
